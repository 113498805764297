<template>
  <div class="toolbar">
    <BaseThemeComponent name="MobileNavBar" />
    <VToolbar
      :class="['header white pa-0']"
      fixed
      app
      :height="$mq === 'xs' ? '70px' : $mq === 'sm' ? '100px' : '120px'"
      scroll-off-screen
      :scroll-threshold="200"
    >
      <VFlex class="nav-left hidden-xs-only" grow shrink>
        <nav>
          <NavBarRoutes
            :routes="
              persistentNavRoutes.filter(
                (r) => !r.position || r.position !== 'right'
              )
            "
          />
        </nav>
      </VFlex>
      <BaseLink :to="{ path: '/' }" class="nav-logo--link">
        <img
          :class="[
            'nav-logo',
            { small: ['xs'].includes($mq) },
            { medium: ['sm', 'md'].includes($mq) },
            { 'ml-2': ['xs', 'sm'].includes($mq) },
          ]"
          :src="navLogoImg"
          :alt="`${getSiteTitle} Logo`"
        />
      </BaseLink>
      <VFlex
        class="nav-right"
        grow
        :shrink="!['xs', 'sm'].includes($mq)"
        text-sm-right
      >
        <nav>
          <VLayout row wrap text-xs-right justify-end align-center>
            <VFlex
              v-for="channel of getSocialChannels"
              :key="channel.name"
              class="nav-route social-icon text-xs-center mx-0 mb-0 mt-1 hidden-sm-and-down"
            >
              <a :href="channel.url" target="_blank" class="py-2 px-2 mr-2">
                <img
                  :src="require(`@assets/images/icon-${channel.name}-blue.svg`)"
                  :alt="channel.name"
                />
              </a>
            </VFlex>
            <VFlex shrink>
              <WithLocationSummaries v-slot="{ locations }">
                <template v-for="(loc, index) of locations">
                  <BaseButton
                    v-if="formattedPhone(loc)"
                    v-show="!['xs', 'sm'].includes($mq)"
                    :key="index"
                    :href="`tel:${formattedPhone(loc)}`"
                    small
                    :class="[
                      $mq === 'sm' ? 'mr-2' : $mq === 'md' ? 'mr-2' : 'mr-0',
                    ]"
                    color="blue darken-4"
                    >Call Us</BaseButton
                  >
                </template>
              </WithLocationSummaries>
              <BaseButton
                small
                :class="[$mq === 'md' ? 'mr-2' : 'mr-0']"
                color="#ff4c3d"
                href="https://resy.com/cities/no/blue-giant"
                target="_blank"
                >Book Now</BaseButton
              >
            </VFlex>
            <BaseButton
              icon
              flat
              color="transparent"
              text-color="black"
              large
              md-icon="menu"
              aria-label="Toggle Mobile Menu"
              class="hidden-sm-and-up mr-0"
              @click="$store.commit('nav/TOGGLE_DRAWER')"
            ></BaseButton>
          </VLayout>
        </nav>
      </VFlex>
    </VToolbar>
  </div>
</template>

<script>
import { authComputed } from '@store/helpers'
import { mapGetters } from 'vuex'
import getSiteAsset from '@utils/get-site-asset'

import NavBarRoutes from '@components/NavBarRoutes'
import WithLocationSummaries from '@dataProviders/WithLocationSummaries'

import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'BlueGiantNavBar',
  components: { NavBarRoutes, WithLocationSummaries },
  data() {
    return {
      persistentNavRoutes: [
        {
          name: 'home',
          title: 'Home',
          exact: true,
        },
        {
          name: 'menu',
          title: 'Menu',
          to: { path: '/locations/blue-giant' },
        },
        {
          name: 'about',
          title: 'About & Contact',
          to: { path: '/about-contact' },
        },
        {
          name: 'social',
          title: 'Social',
          position: 'right',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters('site', ['getSiteTitle', 'getSocialChannels', 'getSiteId']),
    navLogoImg() {
      return getSiteAsset(`site-${this.getSiteId}-navbar-logo.svg`)
    }, // navLogoImg
  },
  methods: {
    formatPhone,
    formattedPhone(location) {
      if (!location) {
        return false
      }
      return formatPhone(getContactDetail(location, 'phone_number'))
    }, // formattedPhone
  },
}
</script>

<style lang="scss">
@use "sass:math";
@import '@design';

.toolbar .v-toolbar {
  z-index: 3;
}

.header {
  background-color: $color-navbar;
}
.nav-left,
.nav-right {
  flex-basis: 50%;
}

.nav-route {
  display: inline-block;
  margin: 0 math.div($size-grid-padding, 2);
  text-transform: uppercase;
  a {
    // TODO (ESS) : globalize fonts
    font-family: 'Karla', sans-serif;
    font-weight: bold;
    color: $color-bluegiant-blue;
    text-decoration: none;
    letter-spacing: 0.15em;
  }
  &.BaseButton a {
    color: $color-button-text;
  }
  &.social-icon {
    flex: 0 1 auto;
    img {
      transition: opacity 0.2s ease;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  &.router-link-active a {
    padding-bottom: 0.4em;
    color: $color-bluegiant-light-blue;
    text-decoration: none;
    cursor: default;
    border-bottom: 2px solid $color-bluegiant-red;
  }
} // nav-route

.nav-logo--link {
  line-height: 0;
}
.nav-logo {
  max-width: 348px;
  cursor: pointer;
  &.medium {
    max-width: 180px;
  }
  &.small {
    max-width: 140px;
  }

  @media (max-width: 320px) {
    max-width: 100px !important;
  }
}

.toolbar {
  .v-toolbar__content {
    position: relative;

    $margin: 5px;

    &::after {
      position: absolute;
      top: $margin;
      left: $margin;
      z-index: 0;
      width: calc(100% - #{$margin * 2});
      height: calc(100% - #{$margin * 2});
      content: '';
      border: 3px solid $color-bluegiant-blue;
    }
    > * {
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 414px) {
  .toolbar .v-toolbar__content {
    padding: 0 5px;
  }
}
</style>
